import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="base">
          <div className="block-table03">
            <p className="footer_nav_p">
              |{" "}
              <Link to="/" onClick={toTop}>
                HOME
              </Link>{" "}
              |{" "}
              <Link to="/about" onClick={toTop}>
                ABOUT
              </Link>{" "}
              |{" "}
              <Link to="/contact" onClick={toTop}>
                CONTACT
              </Link>{" "}
              |{" "}
              <Link to="/privacy" onClick={toTop}>
                PRIVACY
              </Link>{" "}
              |
            </p>
          </div>
          <div className="footer_nav_img">
            <img width="350" height="200" alt="" src="/head/head.svg" />
          </div>
          <h2>Hair Design Roots</h2>

          <div className="text_c">
            <table>
              <tbody>
                <tr>
                  <th>所在地</th>
                  <td>
                    1309
                    <br />
                    Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
                  </td>
                </tr>
                <tr>
                  <th>TEL</th>
                  <td>
                    <Link to="tel:0362806606">(415) 425-9345</Link>
                  </td>
                </tr>
                <tr>
                  <th>E-mail</th>
                  <td>work@sioeiu.life</td>
                </tr>
                <tr>
                  <th>営業時間</th>
                  <td>
                    平日（10:00〜21:00）
                    <br />
                    土曜（7:00〜17:00）
                    <br />
                    日祝（7:00〜15:00）
                  </td>
                </tr>
                <tr>
                  <th>休日</th>
                  <td>不定休</td>
                </tr>
                <tr>
                  <th>最寄駅</th>
                  <td>
                    『JR新橋駅 銀座口』
                    <br />
                    『東京メトロ銀座線新橋駅 3番出口』
                    <br />
                    『東京メトロ日比谷線銀座駅 B3出口』
                  </td>
                </tr>
                <tr>
                  <th>SNS</th>
                  <td>
                    <Link to="/">
                      <img
                        src="/main/sns_button_02.png"
                        width="25"
                        height="25"
                        className="button"
                        alt="Hair Design Roots｜銀座｜ヘアセット・ヘアメイク"
                      />
                    </Link>{" "}
                    &nbsp;
                    <Link to="/" target="_blank">
                      <img
                        src="/main/sns_button_03.png"
                        width="25"
                        height="25"
                        className="button"
                        alt="Hair Design Roots｜銀座｜ヘアセット・ヘアメイク"
                      />
                    </Link>{" "}
                    &nbsp;
                    <Link to="/" target="_blank">
                      <img
                        src="/main/sns_button_06.png"
                        width="25"
                        height="25"
                        className="button"
                        alt="Hair Design Roots｜銀座｜ヘアセット・ヘアメイク"
                      />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="copyright">
        <div className="container">
          <div className="col-xs-12 col-md-6 col-lg-6 box1">
            <p>
              Copyright © {currentYear} ginza-roots.com. All Rights Reserved.
            </p>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6 box3">
            <p>
              <Link to="/privacy">プライバシーポリシー</Link> |{" "}
              <Link to="/">SITEMAP</Link> | <Link to="/">LINKS</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
